// components/ScrollToPosition.js
"use client";
import { useEffect } from "react";

const ScrollToPosition = ({ position = 260 }) => {
  useEffect(() => {
    window.scrollTo({
      top: position,
      behavior: "smooth",
    });
  }, [position]);

  return null; // Bu bileşen sadece scroll işlevi için kullanılacak, içerik render etmez
};

export default ScrollToPosition;
