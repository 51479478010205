"use client";
import { useEffect, useState } from "react";

export default function CSP() {
  const [nonce, setNonce] = useState("");

  useEffect(() => {
    // Nonce oluşturma
    const generatedNonce = Buffer.from(crypto.randomUUID()).toString("base64");
    setNonce(generatedNonce);

    // CSP başlığı oluşturma, nonce ekliyoruz
    const cspHeader = `
    default-src 'self' *.google.com *.googlesyndication.com *.tradingview.com;
    script-src 'self' 'unsafe-inline' 'unsafe-eval' *.googletagmanager.com *.google.com *.gstatic.com *.googlesyndication.com *.tradingview.com *.static.cloudflareinsights.com news.google.com ;
    style-src 'self' 'unsafe-inline' https://fonts.googleapis.com *.google.com *.gstatic.com *.tradingview.com news.google.com www.googletagmanager.com;
    img-src 'self' https://www.uscmarkets.com *.google.com.tr *.googletagmanager.com *.google.com *.gstatic.com *.googlesyndication.com *.doubleclick.net *.googleusercontent.com *.tradingview.com blob: data:;
    font-src 'self' *.fonts.gstatic.com *.google.com *.tradingview.com https://fonts.gstatic.com;
    connect-src 'self' *.google.com *.gstatic.com *.googlesyndication.com https://www.uscmarkets.com *.tradingview.com *.doubleclick.net ;
    frame-src 'self' *.recaptcha.net *.google.com *.googlesyndication.com *.tradingview.com *.tradingview-widget.com *.doubleclick.net;
    object-src 'none';
    base-uri 'self';
    form-action 'self';
    upgrade-insecure-requests;
    block-all-mixed-content;
  `
      .replace(/\n/g, "")
      .trim();

    // CSP başlığını ekle
    const meta = document.createElement("meta");
    meta.httpEquiv = "Content-Security-Policy";
    meta.content = cspHeader;
    document.head.appendChild(meta);
  }, []);

  return null; // Bu bileşen sadece CSP başlığını eklemek için kullanılıyor
}
