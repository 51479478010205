"use client";
// SearchBoxWithResults.js
import React, { useState } from "react";
import Link from "next/link"; // Next.js Link bileşenini içe aktarın
import "@/styles/serachBox.css";

const SearchBoxWithResults = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filtered = data.filter((item) => {
      const combinedKeyword =
        `${item.Kod} + ${item["Şirket Unvanı"]}`.toLowerCase();
      return combinedKeyword.includes(value.toLowerCase());
    });

    setFilteredData(filtered);
    setShowResults(true);
  };

  return (
    <div className="search-container">
      <input
        className="search-input"
        type="text"
        placeholder="Başka Hisse Ara..."
        value={searchTerm}
        onChange={handleChange}
        onBlur={() => setTimeout(() => setShowResults(false), 200)}
      />
      {showResults && (
        <div className="autocomplete-results">
          {filteredData.map((item, index) => {
            const combinedKeyword = `${item.Kod} ${item["Şirket Unvanı"]}`;
            return (
              <Link
                key={index}
                href={`/${item.Link}`}
                className="autocomplete-item"
              >
                {combinedKeyword}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchBoxWithResults;
