"use client";
import Script from "next/script";
import React, { useEffect } from "react";
import { useAds } from "@/components/AdsProvider";

const SubscriptionChecker = () => {
  const { setShowAds } = useAds();

  // SWG kitaplığının yüklenmesini dinlemek için bir callback fonksiyon
  const onScriptLoad = () => {
    //  console.log("SWG Script yüklendi ve çalıştırılıyor.");

    (self.SWG_BASIC = self.SWG_BASIC || []).push((basicSubscriptions) => {
      basicSubscriptions.setOnEntitlementsResponse((entitlementsPromise) => {
        console.log("Fetching entitlement information...");
        entitlementsPromise.then((entitlements) => {
          const isSubscribed =
            entitlements.enablesThisWithCacheableEntitlements();
          setShowAds(!isSubscribed);
          console.log(
            `Subscription status read: ${
              isSubscribed ? "Subscribed" : "Not subscribed"
            }. Ads will ${isSubscribed ? "be hidden" : "be displayed"}.`
          );
          console.log("Entitlements detayları:", entitlements);
        });
      });
    });
  };

  return (
    <>
      <Script
        src="https://news.google.com/swg/js/v1/swg-basic.js"
        strategy="afterInteractive"
        onLoad={onScriptLoad}
      />
    </>
  );
};

export default SubscriptionChecker;
